import React from "react";
import styles from "../styles/style";
//import Button from "./Button";

const CTA = () => (
  <div className={`bg-primary  ${styles.flexCenter}`}>
    <div className={`${styles.boxWidth}`}>
      <section
        className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:my-6 my-4 md:flex-row flex-col bg-black-gradient-3 rounded-[20px] box-shadow`}
      >
        <div className="flex-1 flex flex-col">
          <h2 className={`${styles.heading3} ss:leading-[80.8px]`}>
            <span className="text-gradient-yellow">Call us today!</span>
          </h2>

          {/* <h2 className="font-poppins font-semibold xl:text-[48px] lg:text-[44px] md:text-[38px] sm:text-[34px] ss:text-[28px] xs:text-[28px] text-[20px] text-blue-600 xs:leading-[76.8px] leading-[66.8px] w-full">
            Try our service now!
          </h2> */}

          {/* <h4 className="font-poppins font-semibold text-blue-600 text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4> */}

          <p
            className={`${styles.paragraph3} max-w-[680px] font-simibold  mt-5`}
          >
            Intact Electrical providing the next level services, professionalism
            and craftsmanship, as we strive to achieve 100% customer
            satisfaction.
          </p>
        </div>

        <div
          className={`${styles.flexCenter} sm:ml-10 ml-0 sm:mt-5 mt-10 mb-5`}
        >
          {/* <Button label="Get a quote today" /> */}
          <div className="block">
            <button
              className="py-4 px-9 w-full font-semibold border text-white hover:text-[#33bbcf]  transition ease-in-out duration-200 rounded-xl  "
              type="button"
            >
              Get in touch!
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
);

export default CTA;
