import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";

import rehypeReact from "rehype-react";
import Button from "../components/Button";
import Cta from "../components/CTA";
import Title from "../components/Title";
import Headings from "../components/Headings";

import ReactLightboxV2 from "../components/ReactLightboxV2";

import {
  electricalInstallationsFeaturedContent,
  eicReportsPatTestingFeaturedContent,
  wiringServicesFeaturedContent,
  LightingServicesFeaturedContent,
  fuseboardServicesFeaturedContent,
  securitySolutionsFeaturedContent,
  electricalInstallationsGalleryHeader,
  eicrPatGalleryHeader,
  wiringServicesGalleryHeader,
  lightingSolutionsGalleryHeader,
  fuseboardServicesGalleryHeader,
  securitySolutionsGalleryHeader,
} from "../constants";

import styles from "../styles/style";

const Service = ({ data, pageContext }) => {
  //console.log("data: ", data);

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      button: Button,
      cta: Cta,
      title: Title,
      //whychooseus: WhyChooseUs,
      headings: Headings,
    },
  }).Compiler;

  //console.log(data);

  const { htmlAst } = data.markdownRemark;
  const {
    title,
    heroImage,
    heroTitleTop,
    heroTitleBottom,
    excerpt,
    btnLeft,
    btnLeftLink,
    btnRight,
    btnRightLink,
  } = data.markdownRemark.frontmatter;

  //console.log("Title: ", title);

  const electricalInstallationsFeaturedImages =
    data.electricalInstallationsFeaturedImages.nodes;

  const eicReportsPatTestingfeaturedImages =
    data.eicReportsPatTestingfeaturedImages.nodes;

  const wiringServicesfeaturedImages = data.wiringServicesfeaturedImages.nodes;

  const lightingServicesFeaturedImages =
    data.lightingServicesFeaturedImages.nodes;

  const fuseboardServicesfeaturedImages =
    data.fuseboardServicesfeaturedImages.nodes;

  const securitySolutionsfeaturedImages =
    data.securitySolutionsfeaturedImages.nodes;

  let backgroundImg = getImage(heroImage?.childImageSharp?.gatsbyImageData);

  return (
    <>
      <Seo 
        title={title}
        description={excerpt}
      />
      <HeroV8
        heroImage={backgroundImg}
        heroTitleTop={heroTitleTop}
        heroTitleBottom={heroTitleBottom}
        excerpt={excerpt}
        btnLeft={btnLeft}
        btnLeftLink={btnLeftLink}
        btnRight={btnRight}
        btnRightLink={btnRightLink}
      />
      {title && title === "Electrical Installations" ? (
        <FeaturedContent
          featuredContent={electricalInstallationsFeaturedContent}
          featuredContentImages={electricalInstallationsFeaturedImages}
        />
      ) : title && title === "EICR’s - PAT Testing" ? (
        <FeaturedContent
          featuredContent={eicReportsPatTestingFeaturedContent}
          featuredContentImages={eicReportsPatTestingfeaturedImages}
        />
      ) : title && title === "Wiring Services" ? (
        <FeaturedContent
          featuredContent={wiringServicesFeaturedContent}
          featuredContentImages={wiringServicesfeaturedImages}
        />
      ) : title && title === "Lighting Services" ? (
        <FeaturedContent
          featuredContent={LightingServicesFeaturedContent}
          featuredContentImages={lightingServicesFeaturedImages}
        />
      ) : title && title === "Fuse boards & Regulations" ? (
        <FeaturedContent
          featuredContent={fuseboardServicesFeaturedContent}
          featuredContentImages={fuseboardServicesfeaturedImages}
        />
      ) : title && title === "Security Solutions" ? (
        <FeaturedContent
          featuredContent={securitySolutionsFeaturedContent}
          featuredContentImages={securitySolutionsfeaturedImages}
        />
      ) : null}

      <div className="w-full article mb-4">
        <div className={`bg-[#FFFFFF] ${styles.paddingX} ${styles.flexCenter}`}>
          <div className={`${styles.boxWidth}`}>
            <section className="">
              <div>{renderAst(htmlAst)}</div>
            </section>
          </div>
        </div>
      </div>

      {title && title === "Electrical Installations" ? (
        <ReactLightboxV2
          images={data.electricalInstallationsGalleryImages.nodes}
          galleryContent={electricalInstallationsGalleryHeader}
          location="electrical-installations"
        />
      ) : title && title === "EICR’s - PAT Testing" ? (
        <ReactLightboxV2
          images={data.eicrPatGalleryImages.nodes}
          galleryContent={eicrPatGalleryHeader}
          location="eicr-Reports"
        />
      ) : title && title === "Wiring Services" ? (
        <ReactLightboxV2
          images={data.wiringServicesGalleryImages.nodes}
          galleryContent={wiringServicesGalleryHeader}
          location="Wiring Services"
        />
      ) : title && title === "Lighting Services" ? (
        <ReactLightboxV2
          images={data.lightingServicesGalleryImages.nodes}
          galleryContent={lightingSolutionsGalleryHeader}
          location="Lighting Services"
        />
      ) : title && title === "Fuse boards & Regulations" ? (
        <ReactLightboxV2
          images={data.fuseboardGalleryImages.nodes}
          galleryContent={fuseboardServicesGalleryHeader}
          location="Lighting Services"
        />
      ) : title && title === "Security Solutions" ? (
        <ReactLightboxV2
          images={data.securityServicesGalleryImages.nodes}
          galleryContent={securitySolutionsGalleryHeader}
          location="Lighting Services"
        />
      ) : null}
    </>
  );
};

export default Service;

export const query = graphql`
  query ServiceQuery($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      htmlAst
      frontmatter {
        title
        tag
        heroTitleTop
        heroTitleBottom
        excerpt
        btnLeft
        btnLeftLink
        btnRight
        btnRightLink
        # images {
        #   name
        #   childImageSharp {
        #     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        #   }
        # }
        heroImage {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 2.0
            )
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        # featuredImage {
        #   childImageSharp {
        #     gatsbyImageData(width: 800)
        #   }
        # }
      }
    }
    electricalInstallationsFeaturedImages: allFile(
      filter: {
        name: { in: [ "07-power-sockets-electrical-installations", "01-domestic-installations", "10-domestic-installations" ] }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    eicReportsPatTestingfeaturedImages: allFile(
      filter: {
        name: { in: ["01-eicr-pat-services", "05-alfie-barnard", "06-eicr-pat-services"] }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    wiringServicesfeaturedImages: allFile(
      filter: {
        name: {
          in: [
            "cables-v2"
            "02-harvey-nicholes-chiller-upgrade"
            "electrical-wiring-installation"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    lightingServicesFeaturedImages: allFile(
      filter: {
        name: { in: ["14-security-solutions", "dhl-gallery-14", "dhl-gallery-16" ] }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    fuseboardServicesfeaturedImages: allFile(
      filter: {
        name: { in: ["fuseboards-01", "fuseboards-02", "fuseboards-03"] }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    securitySolutionsfeaturedImages: allFile(
      filter: {
        name: {
          in: ["08-biffa-roller-shutter","biffa-gallery-01", "biffa-gallery-12"]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    electricalInstallationsGalleryImages: allFile(
      filter: {
        name: { in: ["dhl-gallery-05", "dhl-gallery-08", "dhl-gallery-16"] }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    eicrPatGalleryImages: allFile(
      filter: { name: { in: ["01-eicr-pat-services","06-eicr-pat-services", "06-gavin-working"] } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    wiringServicesGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "electrical-wiring-installation"
            "02-harvey-nicholes-chiller-upgrade"
            "dhl-gallery-05"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    lightingServicesGalleryImages: allFile(
      filter: {
        name: {
          in: 
          [
            "02-lighting-solutions"
            "04-ladbrokes-project"
            "06-lighting-solutions"
            "16-ladbrokes-office"
            "18-ladbrokes-office"
            "dhl-gallery-16"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    fuseboardGalleryImages: allFile(
      filter: {
        name: { 
          in: [
            "fuseboards-06"            
            "fuseboards-08"
            "fuseboards-10"
            "fuseboards-12"
            "fuseboards-14"
            "fuseboards-16"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.0
          )
        }
      }
    }
    securityServicesGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "01-security-solutions"
            "04-security-solutions"
            "06-security-solutions"
            "biffa-gallery-01"
            "10-security-solutions"
            "16-security-solutions"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
  }
`;
